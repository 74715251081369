<template>
  <section class="header">
    <div class="content container">
      <h1 class="w-50">
        <span class="color-theme">Certifications</span>
        & Appreciations
      </h1>
      <p>
        Our certificates are proof of our trustworthiness, our quality focus and
        our discipline. We rigorously test the final product in our in-house
        laboratory before dispatching for export as well as domestic buyers.
        This helps ensure smooth and risk-free supply chain efficiencies for our
        clients,
      </p>
    </div>
  </section>

  <!--        Certificates-->
  <section id="certificates">
    <div class="container">
      <h1>Our Quality Has <strong>Proof</strong></h1>
      <p>
        The top certification agencies have vetted Shree Balaji Polytech and
        provided us these awesome certificates
      </p>

      <div class="card mt-5">
        <div class="row">
          <div class="col-md-4">
            <div class="certificate">
              <div class="background-overlay"></div>
              <img src="@/assets/images/certificates/appreciation.jpg" />
              <div class="description">
                <label>Certificate of Appreciation</label>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="certificate">
              <div class="background-overlay"></div>
              <img
                src="@/assets/images/certificates/SHREE_BALAJI_POLYTECH_PRODUCTS_PVT._LTD._MQA_-_CE_page-0001.jpg"
              />
              <div class="description">
                <label>Certificate of Compliance</label>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="certificate">
              <div class="background-overlay"></div>
              <img src="@/assets/images/certificates/en681.jpg" />
              <div class="description">
                <label>EN 681-1:1996 / A3:2005</label>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="certificate">
              <div class="background-overlay"></div>
              <img
                src="@/assets/images/certificates/SBPPPL-WRAS-2022-2027.jpg"
              />
              <div class="description">
                <label>SBPPPL-WRAS-2022-2027</label>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="certificate">
              <div class="background-overlay"></div>
              <img
                src="@/assets/images/certificates/DECLARATION-OF-CONFORMITY.jpg"
              />
              <div class="description">
                <label>DECLARATION OF CONFORMITY</label>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="certificate">
              <div class="background-overlay"></div>
              <img
                src="@/assets/images/certificates/SBPPPL-ISO-9001-2015-RENEWED.jpg"
              />
              <div class="description">
                <label>SBPPPL ISO 9001 2015 RENEWED</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Certificates",
};
</script>

<style scoped lang="scss">
.header {
  height: 80vh;
  background-color: red;
  margin-top: -6.5rem;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.1)
    ),
    url("../assets/images/certificates/header.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;

  .content {
    color: white;
    text-align: left;
    margin-top: 4rem;

    h1 {
      font-size: 3rem;
      width: 70%;
      border-left: 8px solid $theme-color;
      padding-left: 1rem;
    }

    p {
      width: 50%;
      margin-top: 1.2rem;
      color: white;
    }
  }

  @media (min-width: 1400px) {
    height: 50vh;
  }
}

#certificates {
  margin: 6rem 0;
}

#certificates .card {
  box-shadow: 0 0 8px #0003;
  padding: 1rem;
}

#certificates .row [class*="col"] {
  margin-top: 1rem;
}

#certificates .certificate {
  cursor: pointer;
  border: 1px solid #0002;
  position: relative;
  overflow: hidden;
  height: 240px;
}

.certificate .background-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(2, 13, 38, 0.69);
  opacity: 0;
  transition: all ease-in-out 300ms;
}

.certificate:hover .background-overlay {
  opacity: 1;
}

.certificate img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
}

.certificate .description {
  background: #f87128 !important;
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  padding: 1rem;
  color: white;
  z-index: 2;
  transition: all ease-in-out 300ms;
}

.certificate .description label {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 0.08rem;
}

.certificate .description p {
  font-size: 0.8rem;
  color: white;
  font-weight: 300;
  margin-bottom: 0;
}

.certificate:hover .description {
  bottom: 0;
}
</style>
